/* ********************************  thin 100 *********************************************** */
@font-face {
  font-family: "Poppins-Thin";
  src: url("../../assets/fonts/poppins/Poppins-Thin.ttf");
  /* font-weight: 100; */
}

@font-face {
  font-family: "Poppins-ThinItalic";
  src: url("../../assets/fonts/poppins/Poppins-ThinItalic.ttf");
  /* font-weight: 100;
      font-style: italic; */
}

/* ********************************  very light 200 *********************************************** */
@font-face {
  font-family: "Poppins-ExtraLight";
  src: url("../../assets/fonts/poppins/Poppins-ExtraLight.ttf");
  /* font-weight: 200; */
}

@font-face {
  font-family: "Poppins-ExtraLightItalic";
  src: url("../../assets/fonts/poppins/Poppins-ExtraLightItalic.ttf");
  /* font-weight: 200;
      font-style: italic; */
}

/* ********************************  light 300 *********************************************** */
@font-face {
  font-family: "Poppins-Light";
  src: url("../../assets/fonts/poppins/Poppins-Light.ttf");
  /* font-weight: 300; */
}

@font-face {
  font-family: "Poppins-LightItalic";
  src: url("../../assets/fonts/poppins/Poppins-LightItalic.ttf");
  /* font-weight: 300;
      font-style: italic; */
}

/* ********************************  regular 400 *********************************************** */
@font-face {
  font-family: "Poppins-Regular";
  src: url("../../assets/fonts/poppins/Poppins-Regular.ttf");
  /* font-weight: 500; */
}

// @font-face {
//   font-family: "Poppins-RegularItalic";
//   src: url("../../assets/fonts/poppins/Poppins-RegularItalic.ttf");
//   /* font-weight: 500;
//       font-style: italic; */
// }

/* ********************************  normal 500 *********************************************** */
@font-face {
  font-family: "Poppins-Medium";
  src: url("../../assets/fonts/poppins/Poppins-Medium.ttf");
  /* font-weight: normal; */
}

@font-face {
  font-family: "Poppins-MediumItalic";
  src: url("../../assets/fonts/poppins/Poppins-MediumItalic.ttf");
  /* font-weight: normal;
      font-style: italic; */
}

/* ********************************  semi bold 600 *********************************************** */
@font-face {
  font-family: "Poppins-SemiBold";
  src: url("../../assets/fonts/poppins/Poppins-SemiBold.ttf");
  /* font-weight: 600; */
}

@font-face {
  font-family: "Poppins-SemiBoldItalic";
  src: url("../../assets/fonts/poppins/Poppins-SemiBoldItalic.ttf");
  /* font-weight: 600;
      font-style: italic; */
}

/* ********************************  bold 700 *********************************************** */
@font-face {
  font-family: "Poppins-Bold";
  src: url("../../assets/fonts/poppins/Poppins-Bold.ttf");
  /* font-weight: 700; */
}

@font-face {
  font-family: "Poppins-BoldItalic";
  src: url("../../assets/fonts/poppins/Poppins-BoldItalic.ttf");
  /* font-weight: 700;
      font-style: italic; */
}

/* ******************************** very bold 800 *********************************************** */
@font-face {
  font-family: "Poppins-ExtraBold";
  src: url("../../assets/fonts/poppins/Poppins-ExtraBold.ttf");
  /* font-weight: 800; */
}

@font-face {
  font-family: "Poppins-ExtraBoldItalic";
  src: url("../../assets/fonts/poppins/Poppins-ExtraBoldItalic.ttf");
  /* font-weight: 800;
      font-style: italic; */
}

/* ********************************  black 900 *********************************************** */
@font-face {
  font-family: "Poppins-Black";
  src: url("../../assets/fonts/poppins/Poppins-Black.ttf");
  /* font-weight: 900; */
}

@font-face {
  font-family: "Poppins-BlackItalic";
  src: url("../../assets/fonts/poppins/Poppins-BlackItalic.ttf");
  /* font-weight: 900;
      font-style: italic; */
}

// @import url("https://fonts.googleapis.com/css2?family=Annie+Use+Your+Telescope&display=swap");
